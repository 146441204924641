<template>
  <!-- eslint-disable vue/no-use-v-if-with-v-for -->
  <div>
    <v-tour
      :steps="steps"
      :callbacks="callbacks"
      name="onboarding"
    >
      <template slot-scope="tour">
        <transition name="fade">
          <v-step
            v-for="(step, index) of tour.steps"
            v-if="tour.currentStep === index"
            :key="index"
            :step="step"
            :previous-step="tour.previousStep"
            :next-step="tour.nextStep"
            :stop="tour.stop"
            :is-first="tour.isFirst"
            :is-last="tour.isLast"
            :labels="tour.labels"
          >
            <div
              slot="actions"
              class="v-step__actions"
            >
              <TVButton
                class="v-step__stop"
                size="small"
                theme="float-dark"
                @click="skipTour()"
              >
                {{ closeStepName || 'Sair do tour' }}
              </TVButton>
              <div class="v-step__actions__actions">
                <TVButton
                  v-if="hasPreviousStep"
                  size="small"
                  theme="secondary"
                  @click="$emit('previous', tour)"
                >
                  Anterior
                </TVButton>
                <TVButton
                  v-if="hasNextStep"
                  size="small"
                  @click="$emit('next', tour)"
                >
                  Próximo
                </TVButton>
                <TVButton
                  v-else
                  size="small"
                  @click="$emit('next', tour)"
                >
                  Finalizar
                </TVButton>
              </div>
            </div>
          </v-step>
        </transition>
      </template>
    </v-tour>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const TOUR_ACTIVE_CLASS = 'tour-active'
const BODY_TOUR = 'in-tour'

export default {
  name: 'TourTooltip',
  props: {
    steps: {
      type: Array,
      required: true,
    },
    hasPreviousStep: {
      type: Boolean,
      required: false,
      default: true,
    },
    hasNextStep: {
      type: Boolean,
      required: false,
      default: true,
    },
    closeStepName: {
      type: String,
      required: false,
      default: () => '',
    },
  },
  data() {
    return {
      callbacks: {
        onStart: this.onStartTour,
        onNextStep: this.onNextStep,
        onPreviousStep: this.onPreviousStep,
        onStop: this.onStop,
      },
    }
  },
  computed: {
    ...mapGetters([
      'onboarding',
    ]),
  },
  mounted() {
    setTimeout(() => this.$tours['onboarding'].start(), 1000)
  },
  methods: {
    onStartTour() {
      document.body.classList.add(BODY_TOUR)
      if (this.steps[0].target !== '#v-step-0') {
        const curStepEl = document.querySelector(this.steps[0].target)
        curStepEl.classList.add(TOUR_ACTIVE_CLASS)
      }
    },
    onNextStep(step) {
      const curStepEl = document.querySelector(this.steps[step].target)
      const nextStepEl = document.querySelector(this.steps[step + 1].target)
      curStepEl.classList.remove(TOUR_ACTIVE_CLASS)
      nextStepEl.classList.add(TOUR_ACTIVE_CLASS)
    },
    onPreviousStep(step) {
      const curStepEl = document.querySelector(this.steps[step].target)
      const prevStepEl = document.querySelector(this.steps[step - 1].target)
      curStepEl.classList.remove(TOUR_ACTIVE_CLASS)
      prevStepEl.classList.add(TOUR_ACTIVE_CLASS)
    },
    finishTutorial() {
      this.$tours['onboarding'].stop()
      this.$emit('finish')
    },
    skipTour() {
      this.$tours['onboarding'].stop()
      this.$emit('skip')
    },
    onStop() {
      document.body.classList.remove(BODY_TOUR)
      if (document.querySelector(`.${TOUR_ACTIVE_CLASS}`)) {
        document
          .querySelector(`.${TOUR_ACTIVE_CLASS}`)
          .classList.remove(TOUR_ACTIVE_CLASS)
      }
    },
  }
}
</script>
