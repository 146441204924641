<template>
  <TourTooltip
    :steps="steps"
    :has-previous-step="hasPreviousStep"
    :has-next-step="hasNextStep"
    @previous="previousStep($event)"
    @next="nextStep($event)"
    @skip="$emit('skip')"
  />
</template>

<script>
import TourTooltip from '@/components/TourTooltip'

export default {
  name: 'TourVideo',
  components: {
    TourTooltip,
  },
  props: {
    firstVideoId: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {
      hasPreviousStep: true,
      hasNextStep: true,
      steps: [
        {
          target: '#v-step-5',
          content: `
            <h4 class="v-step__title">Sua videoaula</h4>
            <span class="v-step__body">
              Assista à sua primeira videoaula! ✨
            </span>`,
          params: {
            placement: 'top',
          },
        },
      ],
    }
  },
  methods: {
    nextStep(tour) {
      if (tour.currentStep === tour.steps.length - 1) {
        this.$router.push({
          name: 'video',
          params: {
            channel: this.$route.params.channel,
            video: this.firstVideoId,
          },
        })
        this.$emit('next')
      } else {
        tour.nextStep()
      }
    },
    previousStep(tour) {
      if (tour.currentStep === 0) {
        this.$router.push({
          name: 'home',
        })
        this.$emit('previous')
      } else {
        tour.previousStep()
      }
    },
  },
}
</script>
